/* import __COLOCATED_TEMPLATE__ from './introduction-and-stages.hbs'; */
import Component from '@glimmer/component';
import type CourseModel from 'codecrafters-frontend/models/course';

interface Signature {
  Element: HTMLDivElement;

  Args: {
    course: CourseModel;
  };
}

export default class CourseOverviewPageIntroductionAndStagesComponent extends Component<Signature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'CourseOverviewPage::IntroductionAndStages': typeof CourseOverviewPageIntroductionAndStagesComponent;
  }
}
